import { vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "pt-8 bg-white flex flex-col text-12px xxl:text-14px overflow-hidden h-screen relative w-full font-montserrat" }
const _hoisted_2 = { class: "flex flex-row py-6 align-middle items-center justify-between w-full border-b border-gray-lp-400" }
const _hoisted_3 = { class: "text-20px text-black-lp-200 flex flex-row items-center flex-no-wrap relative" }
const _hoisted_4 = {
  class: "mr-8 font-semibold",
  style: {"align-self":"center"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Breadcrumb = _resolveComponent("Breadcrumb")!
  const _component_AdvancedFilter = _resolveComponent("AdvancedFilter")!
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_TableV2 = _resolveComponent("TableV2")!
  const _component_ModalFormApproval = _resolveComponent("ModalFormApproval")!
  const _component_ModalFormReject = _resolveComponent("ModalFormReject")!
  const _component_Detail = _resolveComponent("Detail")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      _withDirectives(_createVNode("div", {
        class: "fixed right-0 h-full z-20",
        style: 
        `background-color:rgba(0,0,0,0.3); top: 9rem; width: calc(100% - ${
          _ctx.isAdvancedFilterOpened && _ctx.isCollapseSidebar ? `60px` : `264px`
        });`
      
      }, null, 4), [
        [_vShow, _ctx.isAdvancedFilterOpened]
      ]),
      _createVNode(_component_Breadcrumb),
      _createVNode("div", _hoisted_2, [
        _createVNode("div", _hoisted_3, [
          _createVNode("span", _hoisted_4, _toDisplayString(_ctx.getTitle), 1),
          _createVNode(_component_AdvancedFilter, {
            modelValue: _ctx.advancedFilterData,
            isClientPartner: "",
            clientPartnerTitle: "Nama Partner",
            clientPartnerPlaceholder: "Pilih nama partner",
            clientPartnerType: "pos",
            isTopupApproval: "",
            isScroll: false,
            isDisabled: false,
            onChange: _ctx.onChangeAdvancedFilter,
            onOpened: _ctx.onOpenedAdvancedFilter,
            onReset: _ctx.onResetAdvancedFilter
          }, null, 8, ["modelValue", "onChange", "onOpened", "onReset"])
        ])
      ]),
      (
        _ctx.topupApprovalList.length === 0 &&
          !_ctx.isLoading &&
          !_ctx.advancedFilterData.clientPartnerName &&
          !_ctx.advancedFilterData.topupStatus.name &&
          !_ctx.advancedFilterData.bankName.name &&
          !_ctx.advancedFilterData.verificationTopupBy.name &&
          !_ctx.errorCause
      )
        ? (_openBlock(), _createBlock(_component_EmptyState, {
            key: 0,
            customMessage: "Oops.. Belum ada data yang dapat ditampilkan"
          }))
        : (_openBlock(), _createBlock(_component_TableV2, {
            key: 1,
            columns: _ctx.columns,
            data: _ctx.topupApprovalList,
            loading: _ctx.isLoading,
            pagination: _ctx.pagination,
            "onUpdate:pagination": _cache[1] || (_cache[1] = ($event: any) => (_ctx.pagination = $event)),
            totalColumnFreeze: 0,
            isRightFreeze: true,
            isDetailAble: true,
            onClick: _ctx.onClickRow,
            onRequest: _ctx.fetchTopupApprovalList,
            errorCause: _ctx.errorCause,
            onTryAgain: _ctx.fetchTopupApprovalList,
            shadowYStart: "370",
            showRightShadow: "",
            autoHeight: ""
          }, null, 8, ["columns", "data", "loading", "pagination", "onClick", "onRequest", "errorCause", "onTryAgain"]))
    ]),
    _createVNode(_component_ModalFormApproval),
    _createVNode(_component_ModalFormReject),
    (_ctx.isShowDetail)
      ? (_openBlock(), _createBlock(_component_Detail, { key: 0 }))
      : _createCommentVNode("", true)
  ], 64))
}