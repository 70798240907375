
import { Options, Vue } from "vue-class-component";
import EmptyState from "@/app/ui/components/empty-list/index.vue";
import {
  dateToDateString,
  formatPrice
} from "@/app/infrastructures/misc/Utils";
import { TopupApprovalController } from "../../../controllers/TopupApprovalController";
import { TopupApprovalData } from "@/domain/entities/TopupApproval";
import { AccountController } from "@/app/ui/controllers/AccountController";
import ModalFormApproval from "./components/modal-form-approval.vue";
import ModalFormReject from "./components/modal-form-reject.vue";
import debounce from "lodash/debounce";
import Detail from "./components/detail.vue";

@Options({
  components: {
    EmptyState,
    ModalFormApproval,
    ModalFormReject,
    Detail
  }
})
export default class extends Vue {
  mounted() {
    TopupApprovalController.fetchTopupApprovalList();
  }

  get getTitle() {
    return this.$route.meta.name;
  }

  // select
  get isCollapseSidebar() {
    return AccountController.isCollapseSidebar;
  }
  isAdvancedFilterOpened = false;
  onChangeAdvancedFilter = debounce(event => {
    TopupApprovalController.setAdvanceFilterData(event);
    TopupApprovalController.setFirstPage();
    this.fetchTopupApprovalList();
  }, 500);
  onOpenedAdvancedFilter(event: any) {
    this.isAdvancedFilterOpened = event;
  }
  onResetAdvancedFilter = debounce(() => {
    TopupApprovalController.setAdvanceFilterData({
      clientPartnerName: "",
      topupStatus: { name: "", value: "" },
      bankName: { name: "", value: "" },
      verificationTopupBy: { name: "", value: "" }
    });
    TopupApprovalController.setFirstPage();
    this.fetchTopupApprovalList();
  }, 500);
  get advancedFilterData() {
    return TopupApprovalController.advanceFilterData;
  }

  // table
  get topupApprovalList() {
    return TopupApprovalController.topupApprovalList.data;
  }

  get pagination() {
    return TopupApprovalController.topupApprovalList.pagination;
  }

  fetchTopupApprovalList() {
    TopupApprovalController.fetchTopupApprovalList();
  }

  get errorCause() {
    return TopupApprovalController.errorCause;
  }

  get isLoading() {
    return TopupApprovalController.isLoading;
  }

  columns = [
    {
      name: "ID",
      styleHead: "text-black-lp-300 w-24 text-left whitespace-no-wrap",
      render: (item: TopupApprovalData) => {
        return `<div class="text-black-lp-300 capitalize text-left break-all">${item.tumId}</div>`;
      }
    },
    {
      name: "ID Transaksi Genesis",
      styleHead: "text-black-lp-300 w-64 text-left whitespace-no-wrap",
      render: (item: TopupApprovalData) => {
        return `<div class="text-black-lp-300 capitalize text-left break-all">${
          item.genesisTransactionId ? item.genesisTransactionId : "-"
        }</div>`;
      }
    },
    {
      name: "Nama Partner",
      styleHead: "text-black-lp-300 w-64 text-left whitespace-no-wrap",
      render: (item: TopupApprovalData) => {
        return `<div class="text-black-lp-300 capitalize text-left break-words">${item.tumActorName}</div>`;
      }
    },
    {
      name: "Status",
      styleHead: "text-black-lp-300 w-28 text-left whitespace-no-wrap",
      render: (item: TopupApprovalData) => {
        return `<div class="rounded-full capitalize overflow-ellipsis text-left px-2 flex flex-row gap-x-1 font-medium ${this.convertColor(item.tumStatus.toLowerCase())}">
            ${item.tumStatus}
          </div>`;
      }
    },
    {
      name: "Jumlah Permintaan",
      styleHead: "text-black-lp-300 w-40 text-left whitespace-no-wrap",
      render: (item: TopupApprovalData) => {
        return `<div class=" capitalize flex flex-col"><div class="font-medium text-left text-green-lp-100">${formatPrice(
          item.tumAmount
        )}</div></div>`;
      }
    },
    {
      name: "Jumlah Terverifikasi",
      styleHead: "text-black-lp-300 w-40 text-left whitespace-no-wrap",
      render: (item: TopupApprovalData) => {
        const tumVerifiedAmount = `<div class="font-medium text-left text-green-lp-100">${formatPrice(item.tumVerifiedAmount)}</div>`;

        return `<div class=" capitalize flex flex-col">${
          item.tumVerifiedAmount
            ? tumVerifiedAmount
            : "-"
        }</div>`;
      }
    },
    {
      name: "Jumlah Disetujui",
      styleHead: "text-black-lp-300 w-40 text-left whitespace-no-wrap",
      render: (item: TopupApprovalData) => {
        const tumApprovedAmount = `<div class="font-medium text-left text-green-lp-100">${formatPrice(item.tumApprovedAmount)}</div>`;
        return `<div class=" capitalize flex flex-col">${
          !item.tumApprovedAmount
            ? "-"
            : tumApprovedAmount
        }</div>`;
      }
    },
    {
      name: "Nama Bank",
      styleHead: "text-black-lp-300 w-56 text-left whitespace-no-wrap",
      render: (item: TopupApprovalData) => {
        return `<div class="text-black-lp-300 capitalize text-left">${item.tumSenderBankName}</div>`;
      }
    },
    {
      name: "Nama Rekening",
      styleHead: "text-black-lp-300 w-52 text-left whitespace-no-wrap",
      render: (item: TopupApprovalData) => {
        return `<div class="text-black-lp-300 capitalize text-left">${item.tumSenderBankAccountName}</div>`;
      }
    },
    {
      name: "No. Rekening",
      styleHead: "text-black-lp-300 w-50 text-left whitespace-no-wrap",
      render: (item: TopupApprovalData) => {
        return `<div class="text-black-lp-300 capitalize whitespace-no-wrap text-left">${item.tumSenderBankAccountNumber}</div>`;
      }
    },
    {
      name: "Tanggal Transaksi",
      styleHead: "text-black-lp-300 w-40 text-left whitespace-no-wrap",
      render: (item: TopupApprovalData) => {
        return `<div class="text-black-lp-300 flex flex-col capitalize text-left">${this.formatDate(
          item.tumCreatedAt
        )}<span class="text-gray-lp-500">${item.tumCreatedName}</span></div>`;
      }
    },
    {
      name: "Tanggal Disetujui",
      styleHead: "text-black-lp-300 w-44 text-left whitespace-no-wrap",
      render: (item: TopupApprovalData) => {
        return `<div class="text-black-lp-300 flex flex-col capitalize text-left">${
          item.tumApprovalAt ? this.formatDate(item.tumApprovalAt) : "-"
        }<span class="text-gray-lp-500 break-all">${
          item.tumApprovalAt ? item.tumApprovalName : ""
        }</span></div>`;
      }
    },
    {
      name: "Alasan Penolakan",
      styleHead: "text-black-lp-300 w-56 text-left whitespace-no-wrap",
      render: (item: TopupApprovalData) => {
        return `<div class="text-black-lp-300 capitalize text-left">${
          item.tumRejectReason ? item.tumRejectReason : "-"
        }</div>`;
      }
    }
  ];

  convertColor(status: string) {
    const statuses: any = {
      "approved": "text-green-lp-200 bg-green-lp-300",
      "rejected": "text-red-lp-500 bg-red-lp-600",
      "verified": "text-yellow-lp-100 bg-yellow-lp-200"
    }

    return statuses[status];
  }

  formatDate(date: any) {
    date = date ? new Date(date) : "";
    return date ? dateToDateString(date) : "";
  }

  // detail
  get isShowDetail() {
    return TopupApprovalController.isShowDetail;
  }
  onClickRow(item: TopupApprovalData) {
    TopupApprovalController.setShowDetail(true);
    TopupApprovalController.getDetail(item.tumId);
  }
}
