
/* eslint-disable @typescript-eslint/camelcase */
import { Vue, Options } from "vue-class-component";
import {
  formatDateWithoutTime,
  formatPriceRP,
  fileExtensionFromUrl,
  fileNameFromUrl,
  downloadFile
} from "@/app/infrastructures/misc/Utils";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import UploadImage from "@/app/ui/components/upload-image/index.vue";
import { TopupApprovalController } from "@/app/ui/controllers/TopupApprovalController";
import { TopupApprovalData } from "@/domain/entities/TopupApproval";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { ROLE_ADMIN_FINANCE } from "@/app/infrastructures/misc/RolePermission";

@Options({
  components: {
    Title,
    UploadImage
  }
})
export default class Detail extends Vue {
  onChipStatus(status: string) {
    let chip = "";
    switch (status) {
      case "pending":
        chip =
          "bg-gray-lp-200 text-black-lp-300 rounded-full px-2 text-12px xxl:text-14px";
        break;
      case "verified":
        chip =
          "bg-yellow-lp-200 text-yellow-lp-100 rounded-full px-2 text-12px xxl:text-14px";
        break;
      case "verification rejected":
      case "rejected":
        chip =
          "bg-red-lp-600 text-red-lp-500 rounded-full px-2 text-12px xxl:text-14px";
        break;
      case "approved":
        chip =
          "bg-green-lp-300 text-green-lp-200 rounded-full px-2 text-12px xxl:text-14px";
        break;
      default:
        break;
    }
    return chip;
  }

  formatPriceRP(value: number) {
    return formatPriceRP(value);
  }
  formatDateWithoutTime(date: string) {
    return formatDateWithoutTime(date);
  }

  get fileName() {
    return fileNameFromUrl(this.detailData.tumFileOfProof);
  }

  get isFilePdf() {
    return fileExtensionFromUrl(this.detailData.tumFileOfProof).includes("pdf");
  }

  onClose() {
    TopupApprovalController.setShowDetail(false);
  }
  get detailData(): TopupApprovalData {
    return TopupApprovalController.detailData;
  }

  get isError() {
    return TopupApprovalController.isErrorDetail;
  }

  get errorCause() {
    return TopupApprovalController.errorCauseDetail;
  }

  get isLoading() {
    return TopupApprovalController.isLoadingDetail;
  }

  onApprove() {
    TopupApprovalController.setShowTopupApprovalForm(true);
    TopupApprovalController.setShowDetail(false);
  }
  onReject() {
    TopupApprovalController.setShowTopupRejectionForm(true);
    TopupApprovalController.setShowDetail(false);
  }

  get permissionCheck(): boolean {
    const role = AccountController.accountData.role_type;
    return (
      role === ROLE_ADMIN_FINANCE.ADMIN_FINANCE ||
      role === ROLE_ADMIN_FINANCE.ADMIN_FINANCE_APPROVAL
    );
  }

  onDownload() {
    const url = this.detailData.tumFileOfProof;
    downloadFile(url, fileNameFromUrl(url), fileExtensionFromUrl(url));
  }
}
