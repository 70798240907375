
import { Vue, Options } from "vue-class-component";
import ModalForm from "@/app/ui/components/modal-form/index.vue";
import { TopupApprovalController } from "@/app/ui/controllers/TopupApprovalController";
import { formatPrice } from "@/app/infrastructures/misc/Utils";

@Options({
  components: {
    ModalForm
  }
})
export default class TopupRejectModal extends Vue {
  get selectedTopupApprovalList() {
    return TopupApprovalController.detailData;
  }
  get showRejectModal() {
    return TopupApprovalController.showTopupRejectionForm;
  }
  get isDisableConfirm() {
    return !this.rejectReason;
  }

  showModalFormTopupRejection(val: boolean) {
    return TopupApprovalController.setShowTopupRejectionForm(val);
  }

  onBack() {
    this.showModalFormTopupRejection(false);
    this.showCloseConfirmModal = true;
  }

  rejectReason = "";

  formatPrice(val: number) {
    return formatPrice(val);
  }

  // confirmation
  showConfirmModal = false;
  get isSuccessSubmit() {
    return TopupApprovalController.isSuccessReject;
  }
  showCloseConfirmModal = false;
  get isLoading() {
    return TopupApprovalController.isLoadingReject;
  }

  setSuccessSubmit(val: boolean) {
    TopupApprovalController.setSuccessReject(val);
  }

  onReject() {
    this.showConfirmModal = true;
    this.showModalFormTopupRejection(false);
  }

  //save
  onSubmit() {
    this.showConfirmModal = false;
    TopupApprovalController.rejectTopupApproval({
      id: this.selectedTopupApprovalList.tumId,
      reason: this.rejectReason
    });
  }

  showDetail() {
    this.showCloseConfirmModal = false;
    TopupApprovalController.setShowDetail(true);
  }
}
