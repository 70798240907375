
import { Options, Vue } from "vue-class-component";
import UploadFile from "@/app/ui/components/upload-file/index.vue";
import { TopupApprovalController } from "@/app/ui/controllers/TopupApprovalController";
import {
  formatPrice,
  formatInputMoneyWithDecimal,
  commaDecimalToDecimal,
  parseInputFloatToFloat
} from "@/app/infrastructures/misc/Utils";
import { TopupApprovalData } from "@/domain/entities/TopupApproval";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { ROLE_ADMIN_FINANCE } from "@/app/infrastructures/misc/RolePermission";

@Options({
  components: {
    UploadFile
  }
})
export default class ModalFormReject extends Vue {
  approvedAmount = "0";

  get payload() {
    return {
      ...this.detailData,
      approvedAmount: commaDecimalToDecimal(
        this.approvedAmount.replace(/\./g, "")
      )
    };
  }

  get isFormValid() {
    return this.payload.approvedAmount && !this.errorAmountToBeApprove;
  }

  onAmountBlur(value: string) {
    this.approvedAmount = this.approvedAmount
      ? commaDecimalToDecimal(value.replace(/\./g, "")).toLocaleString("id")
      : "0";
  }

  get showTopupApprovalForm() {
    return TopupApprovalController.showTopupApprovalForm;
  }
  showModalFormTopupApproval(val: boolean) {
    TopupApprovalController.setShowTopupApprovalForm(val);
  }

  formatMoney(value: string) {
    return formatInputMoneyWithDecimal(value);
  }

  formatPrice(value: number) {
    return formatPrice(value);
  }

  // validate amount to be verified
  get errorAmountToBeApprove() {
    return (
      parseInputFloatToFloat(this.approvedAmount) >
      Number(this.detailData.tumVerifiedAmount)
    );
  }

  // confirmation
  showConfirmModal = false;
  get isSuccessSubmit() {
    return TopupApprovalController.isSuccessApprove;
  }
  showCloseConfirmModal = false;
  get isLoading() {
    return TopupApprovalController.isLoadingApprove;
  }

  setSuccessSubmit(val: boolean) {
    TopupApprovalController.setSuccessApprove(val);
  }

  //save
  onSubmit() {
    this.showConfirmModal = false;
    TopupApprovalController.approveTopupApproval({
      id: this.detailData.tumId,
      amount: this.payload.approvedAmount
    });
  }

  //reset form
  goToDetail() {
    this.showCloseConfirmModal = false;
    TopupApprovalController.setShowDetail(true);
  }

  get detailData(): TopupApprovalData {
    return TopupApprovalController.detailData;
  }

  get permissionCheck(): boolean {
    const role = AccountController.accountData.role_type;
    return (
      role === ROLE_ADMIN_FINANCE.ADMIN_FINANCE ||
      role === ROLE_ADMIN_FINANCE.ADMIN_FINANCE_APPROVAL
    );
  }
}
